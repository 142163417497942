<template>
    <van-empty
        image="search"
        v-if="myPointList.length == 0 && load"
        description="暂无数据"
    />
    <div class="integral-container" v-else>
        <div
            v-for="(item, index) in myPointList"
            :key="index"
            class="integral-item"
        >
            <van-cell-group>
                <van-cell
                    :title="chooseType(item.type)"
                    :value="'积分数: ' + item.points"
                    :label="item.createdAt"
                    value-class="integral-list-item"
                />
            </van-cell-group>
        </div>
    </div>
</template>

<script>
import { getMyPoint } from "@/service";
export default {
    data() {
        return {
            myPointList: [],
            load: false,
        };
    },
    computed: {
        chooseType() {
            return function (type) {
                switch (type) {
                    case "DWDK":
                        return "点位打卡";
                    case "DWPL":
                        return "点位评论";
                    case "DWKSP":
                        return "点位看视频";
                    case "DWDT":
                        return "点位答题";
                    default:
                        return "";
                }
            };
        },
    },
    components: {},
    mounted() {
        this.getMyPoint();
    },
    methods: {
        async getMyPoint() {
            const params = {
                pageNo: 1,
                pageSize: 1000,
                commonId: "",
            };
            const { data } = await getMyPoint(params);
            this.load = true;
            this.myPointList = data.list || [];
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixin";
@import "@/style/_varibles";

.integral-container {
    padding: 0 20px;
    @include wh(100%, auto);
    display: flex;
    flex-direction: column;
    .clock-info {
        @include sc(16px, $--color-text-secondary);
        line-height: 24px;
    }
    .integral-list-item {
        font-size: 24px;
    }
}
</style>